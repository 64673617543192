import { ExportForm } from '@/classes/ExportForm'
import StatsService from '@/services/stats.service'
import { GlobalControl } from '@/classes/stats/GlobalControl.js'
import StatsTitle from '@/components/Stats/StatsTitle/StatsTitle.vue'
import DateRange from '@/components/Graphs/DateRange/DateRange.vue'
import getSymbolFromCurrency from 'currency-symbol-map'
import VueApexCharts from 'vue-apexcharts'
import { Colors } from '@/classes/colors/Colors'
import getEnv from '@/util/env'
import moment from 'moment'
import OverlayManager from '@/components/OverlayManager/OverlayManager.vue'
import LandingPreview from '@/components/Template/LandingEditor/common/landing-preview/LandingPreview.vue'
import EventBus from '@/util/EventBus.js'
import ShareButton from '@/components/Stats/ShareButton/ShareButton.vue'
import ShareModal from '@/components/Stats/ShareModal/ShareModal.vue'
import ConfirmCustomFieldsModal from '@/components/Stats/ConfirmCustomFieldsModal/ConfirmCustomFieldsModal.vue'
import StatsMixin from '../../../../Stats/Mixins/StatsMixin'
import { MailingCalculatedData } from '@/classes/stats/Calculations/Mailing/MailingCalculatedData'
import { MailingGraphicData } from '@/classes/stats/MailingGraphicData'
import StatCard from '@/components/Stats/Card/StatCard.vue'
import Pie from '@/components/Stats/Pie/Pie.vue'
import RadialBar from '@/components/Stats/RadialBar/RadialBar.vue'
import RadialBarWithLegend from '@/components/Stats/RadialBar/RadialBarWithLegend.vue'
import SimpleBar from '@/components/Stats/SimpleBar/SimpleBar.vue'
import StackedMultiple from '@/components/Stats/StackedColumn/StackedMultiple.vue'

export default {
  name: 'CampaignMailingStats',
  components: {
    StatsTitle,
    DateRange,
    apexchart: VueApexCharts,
    OverlayManager,
    LandingPreview,
    ShareButton,
    ShareModal,
    ConfirmCustomFieldsModal,
    StatCard,
    Pie,
    RadialBar,
    RadialBarWithLegend,
    SimpleBar,
    StackedMultiple,
  },
  mixins: [StatsMixin],
  data: function () {
    return {
      globalControl: new GlobalControl(),
      user: JSON.parse(localStorage.getItem('user')),
      loading: true,
      options: {},
      statuses: [],
      content: [],
      searchTerm: '',
      itemsPerPage: 10,
      pageCount: 0,
      page: 1,
      totalSendings: 0,
      headers: [
        {
          text: this.$t('ID Envío'),
          value: 'sending_id',
        },
        {
          text: this.$t('Fecha'),
          value: 'sending_date',
        },
        {
          name: 'tags',
          text: this.$t('Tags'),
          value: 'tags',
        },
        {
          text: this.$t('Enviados'),
          value: 'sent_total',
        },
        {
          text: this.$t('Recibidos'),
          value: 'delivered_total',
        },
        {
          text: this.$t('No Recibidos'),
          value: 'undelivered_total',
        },
        {
          text: this.$t('Abiertos'),
          value: 'opened_unique',
        },
        {
          text: this.$t('Clics'),
          value: 'clicked_unique',
        },
        {
          text: 'Hard Bounces',
          value: 'hard_bounced_unique',
        },
        {
          text: 'Soft Bounces',
          value: 'soft_bounced_unique',
        },
        {
          text: this.$t('Bajas'),
          value: 'unsubscribed_unique',
        },
        {
          text: this.$t('Coste'),
          align: 'start',
          value: 'cost',
          class: 'w-6',
        },
      ],
      currencySymbol: '',
      additional: undefined,
      data: [],
      deliveredChart: {
        options: {},
      },
      ratioOpenChart: {
        options: {},
      },
      ratioClickThroughChart: {
        options: {},
      },
      ratioClickOpenChart: {
        options: {},
      },
      ratioBouncedChart: {
        options: {},
      },
      unsubscribed: {
        percentage: '0',
        total: 0,
        deliveredTotal: 0,
        unsubscribedUnique: 0,
      },
      perPage: '5',
      exportForm: new ExportForm(),
      ready: true,
      campaign: null,
      template: null,
      arrStatuses: Colors.statusesColors,
      barChartOptions: {
        title: {
          text: this.$t('Interaciones de usuario'),
        },
        series: [
          {
            name: this.$t('Enviados'),
            data: [],
          },
          {
            name: this.$t('Entregados'),
            data: [],
          },
          {
            name: this.$t('Abiertos'),
            data: [],
          },
          {
            name: this.$t('Clics'),
            data: [],
          },
          {
            name: 'Soft Bounces',
            data: [],
          },
          {
            name: 'Hard Bounces',
            data: [],
          },
          {
            name: this.$t('Bajas'),
            data: [],
          },
        ],
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
        colors: [Colors.colorChartSent, Colors.colorBarDelivered, Colors.colorBarOpened, Colors.colorBarClicked,
          Colors.colorBarSoftBounced, Colors.colorBarHardBounced, Colors.colorBarUnsubscribed],
        xaxis: {
          categories: [],
        },
      },
      selectedHeaders: [],
      sendingDetails: {
        initDate: '',
        endDate: moment().format('YYYY-MM-DD'),
        customFields: false,
        events: [],
        columns: [],
        sendingId: null,
        campaignType: null,
      },
      mailingCalculatedData: new MailingCalculatedData(),
      deliveredRatioGraphic: null,
      campaginDataSource: {},
      readyStats: false,
      cards: [],
      openedRatioGraphicData: null,
      deliveredGraphicData: null,
      clicksRatioGraphicData: null,
      clickToOpenRatioGraphicData: null,
      undeliveredGraphicData: null,
      unsubscribedRatioBarChart: null,
      reBouncedRatioGraphicData: null,
      openedDevicesGraphicData: null,
      interactionItems: [],
      openedDevicesStats: null,
      landing: {},
    }
  },
  methods: {

    setGraphicsData () {
      console.log(this.mailingCalculatedData)
      const mailingGraphicData = new MailingGraphicData(this.mailingCalculatedData, this)
      this.cards = mailingGraphicData.getCardsData()
      this.deliveredRatioGraphic = mailingGraphicData.getDeliveredRatioGraphicGraphicData()
      this.openedRatioGraphicData = mailingGraphicData.getOpenedRatioGraphicData()
      this.deliveredGraphicData = mailingGraphicData.getDeliveredGraphicData()
      this.clicksRatioGraphicData = mailingGraphicData.getClicksRatioGraphicData()
      this.clickToOpenRatioGraphicData = mailingGraphicData.getClickToOpenRatioGraphicData()
      this.undeliveredGraphicData = mailingGraphicData.getUndeliveredGraphicData()
      this.unsubscribedRatioBarChart = mailingGraphicData.getUnsubscribedRatioBarChartGraphicData()
      this.reBouncedRatioGraphicData = mailingGraphicData.getReBouncedRatioGraphicData()
      this.openedDevicesGraphicData = mailingGraphicData.getOpenedDevicesGraphicData(this.openedDevicesStats)
    },

    getPreview () {
      this.$nextTick(() => {
        const landingPreview = this.$refs.landingPreview
        landingPreview.showPreviewFromStructure(this.template)
      })
    },

    sumReduceByKey (items, key) {
      return items.reduce(
        function (a, b) {
          return a + parseInt(b[key])
        }, 0)
    },

    sumReduceDecimalByKey (items, key) {
      return items.reduce(
        function (a, b) {
          return a + parseFloat(b[key])
        }, 0).toFixed(3)
    },

    getDataFromApi (params = {}) {
      EventBus.$emit('showLoading', true)
      this.content = []
      this.loading = true
      params = this.getParams()
      StatsService.getShareMailingCampaignDatatable(params, this.$route.params.shareHash)
        .then(
          (response) => {
            this.readyStats = false
            this.campaginDataSource = response
            this.additional = JSON.parse(JSON.stringify(response.additional))
            this.data = JSON.parse(JSON.stringify(response.data))
            this.setTableData(response.data)
            this.itemsPerPage = parseInt(response.per_page)
            this.page = response.current_page
            this.totalSendings = response.total
            this.setPieChart(this.additional[0])
            this.sendingDetails.initDate = moment(this.data[0].sending_date).format('YYYY-MM-DD')
            this.sendingDetails.sendingId = this.data[0].sending_id
            this.sendingDetails.campaignType = this.data[0].campaign_type_name
            setTimeout(() => {
              this.mailingCalculatedData.calculate(this.campaginDataSource)
              this.setGraphicsData()
              this.readyStats = true
            })
          },
          () => { },
        )
        .finally(() => {
          this.loading = false
          EventBus.$emit('showLoading', false)
        })
    },

    setTableData (data) {
      data.forEach(data => {
        data.undelivered_total = this.calculateUndeliveredTotal(data)
        this.barChartOptions.series[0].data.push(data.sent_total)
        this.barChartOptions.series[1].data.push(data.delivered_total)
        this.barChartOptions.series[2].data.push(data.opened_unique)
        this.barChartOptions.series[3].data.push(data.clicked_unique)
        this.barChartOptions.series[4].data.push(data.soft_bounced_unique)
        this.barChartOptions.series[5].data.push(data.hard_bounced_unique)
        this.barChartOptions.series[6].data.push(data.unsubscribed_total)
        this.barChartOptions.xaxis.categories.push(this.campaign.campaignName + '- Envío ' + data.sending_id)
      })
      this.content = data
    },

    getParams () {
      const params = {
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        searchTerm: this.searchTerm,
        campaignId: this.campaign.campaignId,
      }

      for (let i = 0; i < this.options.sortBy.length; i++) {
        params['sortBy[' + i + '][field]'] = this.options.sortBy[i]
        params['sortBy[' + i + '][dir]'] = this.options.sortDesc[i]
          ? 'asc'
          : 'desc'
      }
      return params
    },

    calculateUndeliveredTotal (data) {
      let fixedUndeliveredTotal = parseInt(data.hard_bounced_unique) + parseInt(data.soft_bounced_unique) + parseInt(data.rejected_total)
      if (fixedUndeliveredTotal < 0) {
        fixedUndeliveredTotal = 0
      }
      return fixedUndeliveredTotal
    },

    calculateUnopened (data) {
      return parseInt(data.delivered_total) - parseInt(data.opened_unique)
    },

    // eslint-disable-next-line max-lines-per-function
    setPieChart (totals) {
      if (totals === undefined) {
        return
      }

      // delivered chart
      totals.delivered_total = parseInt(totals.delivered_total)
      totals.undelivered_total = this.calculateUndeliveredTotal(totals)
      totals.unopened = this.calculateUnopened(totals)

      this.deliveredChart.percentage = totals.delivered_total === 0 ? totals.delivered_total : ((totals.delivered_total / totals.sent_total) * 100).toFixed(2)
      this.deliveredChart.series = [totals.delivered_total, totals.undelivered_total]
      this.deliveredChart.dataInfo = [
        {
          label: this.$t('Enviados').toString(),
          value: totals.sent_total,
        },
        {
          label: this.$t('Entregados').toString(),
          value: totals.delivered_total,
        },
        {
          label: this.$t('No entregados').toString(),
          value: totals.undelivered_total,
        },
      ]
      this.deliveredChart.options = {
        legend: {
          show: false,
        },
        fill: {
          colors: [Colors.colorChartDelivered, Colors.colorChartUndelivered],
        },
        labels: [this.$t('Entregados').toString(), this.$t('No Entregados').toString()],
      }

      // Ratio opened chart
      this.ratioOpenChart.percentage = parseInt(totals.opened_unique) === 0 ? totals.opened_unique : ((totals.opened_unique / totals.delivered_total) * 100).toFixed(2)
      this.ratioOpenChart.series = [parseInt(totals.opened_unique), this.calculateUnopened(totals)]
      this.ratioOpenChart.dataInfo = [
        {
          label: this.$t('Entregados').toString(),
          value: totals.delivered_total,
        },
        {
          label: this.$t('Abiertos').toString(),
          value: totals.opened_unique,
        },
        {
          label: this.$t('No abiertos').toString(),
          value: this.calculateUnopened(totals),
        },
      ]
      this.ratioOpenChart.options = {
        legend: {
          show: false,
        },
        fill: {
          colors: [Colors.colorChartOpened, Colors.colorChartUnopened],
        },
        labels: [this.$t('Abiertos').toString(), this.$t('No abiertos').toString()],
      }

      // Ratio click through chart
      this.ratioClickThroughChart.percentage = parseInt(totals.delivered_total) === 0 ? totals.delivered_total : ((totals.clicked_unique / totals.delivered_total) * 100).toFixed(2)
      this.ratioClickThroughChart.series = [parseInt(totals.delivered_total), parseInt(totals.clicked_unique)]
      this.ratioClickThroughChart.dataInfo = [
        {
          label: this.$t('Entregados').toString(),
          value: totals.delivered_total,
        },
        {
          label: this.$t('Clics únicos').toString(),
          value: totals.clicked_unique,
        },
      ]
      this.ratioClickThroughChart.options = {
        legend: {
          show: false,
        },
        fill: {
          colors: [Colors.colorBarDelivered, Colors.colorChartUniqueClicks],
        },
        labels: [this.$t('Entregados').toString(), this.$t('Clics únicos').toString()],
      }

      // Ratio click open chart
      this.ratioClickOpenChart.percentage = parseInt(totals.opened_unique) === 0 ? totals.opened_unique : ((totals.clicked_unique / totals.opened_unique) * 100).toFixed(2)
      this.ratioClickOpenChart.series = [parseInt(totals.opened_unique), parseInt(totals.clicked_unique)]
      this.ratioClickOpenChart.dataInfo = [
        {
          label: this.$t('Abiertos').toString(),
          value: totals.opened_unique,
        },
        {
          label: this.$t('Clics únicos').toString(),
          value: totals.clicked_unique,
        },
        {
          label: this.$t('Abiertos únicos').toString(),
          value: totals.opened_unique,
        },
      ]
      this.ratioClickOpenChart.options = {
        legend: {
          show: false,
        },
        fill: {
          colors: [Colors.colorChartTotalClicks, Colors.colorChartUniqueClicks],
        },
        labels: [this.$t('Abiertos únicos').toString(), this.$t('Clics únicos').toString()],
      }

      // Ratio bounced chart
      this.ratioBouncedChart.percentage = parseInt(totals.opened_unique) === 0 ? totals.opened_unique : ((totals.clicked_unique / totals.opened_unique) * 100).toFixed(2)
      this.ratioBouncedChart.series = [parseInt(totals.delivered_total), parseInt(totals.hard_bounced_unique), parseInt(totals.soft_bounced_unique)]
      this.ratioBouncedChart.dataInfo = [
        {
          label: this.$t('Enviados').toString(),
          value: totals.sent_total,
        },
        {
          label: this.$t('No Entregados').toString(),
          value: totals.undelivered_total,
        },
        {
          label: 'Hard Bounced',
          value: totals.hard_bounced_unique,
        },
        {
          label: 'Soft Bounced',
          value: totals.soft_bounced_unique,
        },
      ]
      this.ratioBouncedChart.options = {
        legend: {
          show: false,
        },
        fill: {
          colors: [Colors.colorChartDelivered, Colors.colorChartHardBounced, Colors.colorChartSoftBounced],
        },
        labels: [this.$t('No Entregados').toString(), 'Hard Bounced', 'Soft Bounced'],
      }

      // Unsubscribed chart
      const unsubscribedPercentage = (totals.unsubscribed_total / totals.delivered_total) * 100
      this.unsubscribed.percentage = unsubscribedPercentage > 0 ? unsubscribedPercentage.toFixed(2) : '0'
      this.unsubscribed.total = parseInt(totals.unsubscribed_total)
    },

    getColumns () {
      const columns = []
      this.headers.forEach(function (column) {
        columns.push(column.value)
      })
      return columns
    },

    exportCsv () {
      this.exportAction('csv')
    },

    exportPdf () {
      this.exportAction('pdf')
    },

    exportAction (urlSuffix) {
      const url = getEnv('VUE_APP_API_URL') + 'api/stats/mailing/campaign-export-' + urlSuffix + '?' + this.getUrlParams()
      window.location.href = url
    },

    getUrlParams () {
      return new URLSearchParams(this.setFromParamsAndColumns(this.getParams(), this.getColumns())).toString()
    },

    setFromParamsAndColumns (params, columns) {
      const data = {}
      data.campaignId = this.campaign.campaignId
      data.columns = JSON.stringify(columns)
      data.searchTerm = params.searchTerm
      data.sort = params.sortBy ? params.sortBy : ''
      const user = JSON.parse(localStorage.getItem('user'))
      data.token = user.token
      return data
    },

    resetReady () {
      this.ready = false
      setTimeout(() => {
        this.ready = true
      })
    },

    showOverlayPreview () {
      EventBus.$emit('showOverlayPreview')
    },

    selectedColumn (column) {
      let exist = false
      for (let i = 0; i < this.selectedHeaders.length; i = i + 1) {
        if (!exist) {
          exist = this.selectedHeaders[i].value === column
        }
      }
      return exist
    },

    showConfirmCustomFields (actionMode) {
      EventBus.$emit('showConfirmCustomFields', actionMode)
    },
    statusColor (campaign) {
      return this.arrStatuses[campaign.guid]?.color
    },
  },
  computed: {
    interactionHeaders () {
      return [
        {
          text: this.$t('Texto del enlace'),
          value: 'link_text',
        },
        {
          text: this.$t('Url del enlace'),
          value: 'link_url',
        },
        {
          text: this.$t('Clics'),
          value: 'clics',
        },
        {
          text: this.$t('Acciones'),
          value: 'actions',
        },
      ]
    },
    showHeaders () {
      return this.headers.filter(s => this.selectedHeaders.includes(s))
    },
    params (nv) {
      return {
        ...this.options,
        query: this.searchTerm,
      }
    },
    locale () {
      return this.$i18n.locale
    },
  },
  watch: {
    params: {
      handler () {
        this.getDataFromApi()
      },
      deep: true,
    },
    locale () {
      this.setGraphicsData()
    },
  },
  created () {
    this.selectedHeaders = this.headers

    const campaignId = this.$route.params.id
    StatsService.getShareMailingCampaignData(campaignId, this.$route.params.shareHash)
      .then(
        (response) => {
          this.campaign = response.campaign
          this.campaign.campaignName = response.campaign.campaignName
          this.template = JSON.parse(response.template)
          this.currencySymbol = getSymbolFromCurrency(response.currencyCode)
          this.landing = response.landing
          this.landing.structure = this.landing.structure || this.template
          this.openedDevicesStats = response.openedDevicesStats
        },
        (err) => {
          EventBus.$emit('showAlert', 'warning', this.$t(err.response.data.message))
          EventBus.$emit('showLoading', false)
          this.$router.push({ name: 'ChannelMailingStats' })
        },
      )
      .finally(() => {
        this.loading = false
      })
  },
}
