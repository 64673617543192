import { GraphicData } from '@/classes/stats/GraphicData'
import { Colors } from '@/classes/colors/Colors'

export class MailingGraphicData {
  mailingCalculatedData = null
  thatContext = null

  constructor (mailingCalculatedData, thatContext) {
    this.mailingCalculatedData = mailingCalculatedData
    this.thatContext = thatContext
  }

  getCardsData () {
    return [
      {
        title: this.thatContext.$t('Enviados').toString(),
        subTitle: this.mailingCalculatedData.totals.sent_total,
        percentage: 100,
        colorClass: 'gradient-standard',
        percentageColorClass: 'blue',
        cols: 3,
      },
      {
        title: this.thatContext.$t('Aperturas únicas').toString(),
        subTitle: this.mailingCalculatedData.totals.opened_unique,
        percentage: parseFloat(this.mailingCalculatedData.openedPercentage).toFixed(0),
        colorClass: 'gradient-success',
        percentageColorClass: 'green',
        cols: 3,
      },
      {
        title: this.thatContext.$t('Clics únicos').toString(),
        subTitle: this.mailingCalculatedData.totals.clicked_unique,
        percentage: parseFloat(this.mailingCalculatedData.clicksPercentage).toFixed(0),
        colorClass: 'gradient-alert',
        percentageColorClass: 'alert',
        cols: 3,
      },
      {
        title: this.thatContext.$t('Bajas').toString(),
        subTitle: this.mailingCalculatedData.totals.unsubscribed_total,
        percentage: parseFloat(this.mailingCalculatedData.unsubscribedPercentage).toFixed(0),
        colorClass: 'gradient-danger',
        percentageColorClass: 'danger',
        cols: 3,
      },
    ]
  }

  getDeliveredRatioGraphicGraphicData () {
    return new GraphicData(
      this.thatContext.$t('Ratio de entrega'),
      [
        {
          label: this.thatContext.$t('Enviados').toString(),
          value: '<b>' + this.mailingCalculatedData.totals.sent_total + '</b> / ',
          total: this.mailingCalculatedData.totals.sent_total,
        },
        {
          label: this.thatContext.$t('Entregados').toString(),
          value: '<b>' + this.mailingCalculatedData.totals.delivered_total + '</b> / ',
          total: this.mailingCalculatedData.totals.sent_total,
        },
        {
          label: this.thatContext.$t('No entregados').toString(),
          value: '<b>' + this.mailingCalculatedData.totals.undelivered_total + '</b> / ',
          total: this.mailingCalculatedData.totals.sent_total,
        },
      ],
      [100, this.mailingCalculatedData.deliveredPercentage, this.mailingCalculatedData.undeliveredPercentage],
      [this.thatContext.$t('Enviados').toString(), this.thatContext.$t('Entregados').toString(), this.thatContext.$t('No entregados').toString()],
      this.mailingCalculatedData.deliveredPercentage,
      '',
      0,
      0,
      true,
      300,
      [
        Colors.statistics.SENT_TOTAL,
        Colors.statistics.DELIVERED_TOTAL,
        Colors.statistics.UNDELIVERED_TOTAL,
      ],
      )
  }

  getOpenedRatioGraphicData () {
    const isEmpty = (this.mailingCalculatedData.totals.delivered_total + this.mailingCalculatedData.totals.opened_unique) === 0
    const series = isEmpty ? [1] : [this.mailingCalculatedData.totals.opened_unique, this.mailingCalculatedData.totals.unopened]
    const colors = isEmpty ? ['#f1f1f1'] : [
      Colors.statistics.OPENED_UNIQUE,
      Colors.statistics.UNOPENED,
    ]

    const graphicData = new GraphicData(
      this.thatContext.$t('Ratio apertura').toString(),
      [
        {
          label: this.thatContext.$t('Entregados').toString(),
          value: '<b>' + this.mailingCalculatedData.totals.delivered_total + '</b> / ',
          total: this.mailingCalculatedData.totals.sent_total,
        },
        {
          label: this.thatContext.$t('Abiertos').toString(),
          value: '<b>' + this.mailingCalculatedData.totals.opened_unique + '</b> / ',
          total: this.mailingCalculatedData.totals.delivered_total,
        },
        {
          label: this.thatContext.$t('No abiertos').toString(),
          value: '<b>' + this.mailingCalculatedData.totals.unopened + '</b> / ',
          total: this.mailingCalculatedData.totals.delivered_total,
        },
      ],
      series,
      [
        this.thatContext.$t('Abiertos').toString(),
        this.thatContext.$t('No abiertos').toString(),
      ],
      this.mailingCalculatedData.openedPercentage,
      '',
      '',
      0,
      false,
      230,
      colors,
    )

    graphicData.enabledTooltip = !isEmpty
    graphicData.enabledDataLabels = !isEmpty
    return graphicData
  }


  getDeliveredGraphicData () {
    return new GraphicData(
        '',
        [],
        [this.mailingCalculatedData.totals.delivered_total, this.mailingCalculatedData.totals.undelivered_total],
        [this.thatContext.$t('Entregado').toString(), this.thatContext.$t('No entregado').toString()],
        0,
        '',
        0,
        0,
        true,
        200,
        [
          Colors.statistics.DELIVERED_TOTAL,
          Colors.statistics.UNDELIVERED_TOTAL,
        ],
      )
    }

  getOpenedDevicesGraphicData (openedDevicesStats) {
    return new GraphicData(
        '',
        [],
        [openedDevicesStats.totalOpened, openedDevicesStats.totalMobile, openedDevicesStats.totalBrowser],
        [this.thatContext.$t('Abiertos').toString(), this.thatContext.$t('Dispositivo móvil').toString(), this.thatContext.$t('Browser').toString()],
        0,
        '',
        0,
        0,
        true,
        200,
        [
          Colors.statistics.OPENED,
          Colors.statistics.MOBILE,
          Colors.statistics.BROWSER,
        ],
      )
    }

    getClicksRatioGraphicData () {
      return new GraphicData(
        this.thatContext.$t('Ratio de CLICS').toString() + ' (CTR)',
        [
          {
            label: this.thatContext.$t('Enviados').toString(),
            value: '<b>' + this.mailingCalculatedData.totals.sent_total + '</b> / ',
            total: this.mailingCalculatedData.totals.sent_total,
          },
          {
            label: this.thatContext.$t('Entregados').toString(),
            value: '<b>' + this.mailingCalculatedData.totals.delivered_total + '</b> / ',
            total: this.mailingCalculatedData.totals.sent_total,
          },
          {
            label: this.thatContext.$t('Clics').toString(),
            value: '<b>' + this.mailingCalculatedData.totals.clicked_unique + '</b> / ',
            total: this.mailingCalculatedData.totals.delivered_total,
          },
        ],
        [100, this.mailingCalculatedData.deliveredPercentage, this.mailingCalculatedData.clicksPercentage],
        [this.thatContext.$t('Enviados').toString(), this.thatContext.$t('Entregados').toString(), this.thatContext.$t('Clics').toString()],
        this.mailingCalculatedData.clicksPercentage,
        this.thatContext.$t('Clics totales'),
        this.mailingCalculatedData.totals.clicked_unique,
        0,
        true,
        300,
        [
          Colors.statistics.SENT_TOTAL,
          Colors.statistics.DELIVERED_TOTAL,
          Colors.statistics.CLICKS_UNIQUE,
        ],
      )
    }

    getChannelClicksRatioGraphicData () {
      const graphicData = this.getClicksRatioGraphicData()
      graphicData.chartHeight = 250
      graphicData.graphicClass = 'mt-2'
      return graphicData
    }

    getChannelClickToOpenRatioGraphicData () {
      const graphicData = this.getClickToOpenRatioGraphicData()
      graphicData.chartHeight = 250
      graphicData.graphicClass = 'mt-2'
      return graphicData
    }

    getClickToOpenRatioGraphicData () {
      return new GraphicData(
        this.thatContext.$t('Ratio Click-to-Open (CTOR)').toString(),
        [
          {
            label: this.thatContext.$t('Abiertos').toString(),
            value: '<b>' + this.mailingCalculatedData.totals.opened_total + '</b> / ',
            total: this.mailingCalculatedData.totals.delivered_total,
          },
          {
            label: this.thatContext.$t('Abiertos Únicos').toString(),
            value: '<b>' + this.mailingCalculatedData.totals.opened_unique + '</b> / ',
            total: this.mailingCalculatedData.totals.delivered_total,
          },
          {
            label: this.thatContext.$t('Clics').toString(),
            value: '<b>' + this.mailingCalculatedData.totals.clicked_total + '</b> / ',
            total: this.mailingCalculatedData.totals.delivered_total,
          },
          {
            label: this.thatContext.$t('Clics Únicos').toString(),
            value: '<b>' + this.mailingCalculatedData.totals.clicked_unique + '</b> / ',
            total: this.mailingCalculatedData.totals.delivered_total,
          },
        ],
        [
          this.mailingCalculatedData.openedTotalPercentage,
          this.mailingCalculatedData.openedUniquePercentage,
          this.mailingCalculatedData.clicksTotalPercentage,
          this.mailingCalculatedData.clicksUniquePercentage,
        ],
        [
          this.thatContext.$t('Abiertos').toString(),
          this.thatContext.$t('Abiertos Únicos').toString(),
          this.thatContext.$t('Clics').toString(),
          this.thatContext.$t('Clics Únicos').toString(),
        ],
        this.mailingCalculatedData.clickToOpenPercentage,
        this.thatContext.$t('Clics totales'),
        this.mailingCalculatedData.totals.clicked_total,
        0,
        true,
        300,
        [
          Colors.statistics.OPENED,
          Colors.statistics.OPENED_UNIQUE,
          Colors.statistics.CLICKS,
          Colors.statistics.CLICKS_UNIQUE,
        ],
      )
    }


    getUndeliveredGraphicData () {
      return new GraphicData(
          '',
          [],
          [this.mailingCalculatedData.totals.soft_bounced_unique, this.mailingCalculatedData.totals.hard_bounced_unique],
          [this.thatContext.$t('Soft Bounces').toString(), this.thatContext.$t('Hard Bounces').toString()],
          0,
          '',
          0,
          0,
          true,
          200,
          [
            Colors.statistics.SOFT_BOUNCES,
            Colors.statistics.HARD_BOUNCES,
          ],
        )
      }

  getUnsubscribedRatioBarChartGraphicData () {
    return new GraphicData(
      this.thatContext.$t('Ratio bajas'),
      [
        {
          label: this.thatContext.$t('Entregados').toString(),
          value: '<b>' + this.mailingCalculatedData.totals.delivered_total + '</b> / ',
          total: this.mailingCalculatedData.totals.delivered_total,
        },
        {
          label: this.thatContext.$t('Bajas').toString(),
          value: '<b>' + this.mailingCalculatedData.totals.unsubscribed_total + '</b> / ',
          total: this.mailingCalculatedData.totals.delivered_total,
        },
      ],
      [this.mailingCalculatedData.totals.delivered_total, this.mailingCalculatedData.totals.unsubscribed_total],
      [this.thatContext.$t('Entregados').toString(), this.thatContext.$t('Bajas').toString()],
      this.mailingCalculatedData.unsubscribedPercentage,
      '',
      0,
      0,
      true,
      200,
      [
        Colors.statistics.DELIVERED_TOTAL,
        Colors.statistics.UNSUBSCRIBED_TOTAL,
      ],
    )
  }

  getReBouncedRatioGraphicData () {
    return new GraphicData(
      this.thatContext.$t('Ratio de rebotes'),
      [
        {
          label: this.thatContext.$t('Enviados').toString(),
          value: '<b>' + this.mailingCalculatedData.totals.sent_total + '</b> / ',
          total: this.mailingCalculatedData.totals.sent_total,
        },
        {
          label: this.thatContext.$t('No entregados').toString(),
          value: '<b>' + this.mailingCalculatedData.totals.undelivered_total + '</b> / ',
          total: this.mailingCalculatedData.totals.sent_total,
        },
      ],
      [this.mailingCalculatedData.totals.sent_total, this.mailingCalculatedData.totals.undelivered_total],
      [this.thatContext.$t('Enviados').toString(), this.thatContext.$t('No entregados').toString()],
      this.mailingCalculatedData.undeliveredPercentage,
      '',
      0,
      0,
      true,
      200,
      [
        Colors.statistics.SENT_TOTAL,
        Colors.statistics.UNDELIVERED_TOTAL,
      ],
    )
  }
}
